import { TProductCard } from '@licommon/components/product-card'
import { Category } from '../components/category/types/Category'
import { TRangeCard } from '../components/product-card/calibo-product-card/CaliboProductCard'
import { AttributesArray } from '../components/product/single-product-components/AttributesArray'
import { Label } from './Label'

export type Product = {
    id?: number
    document: ProductDocument
    model: ProductModel
}

export type ProductModel = {
    parent: ProductParent
}

export type ProductContent = {
    id: number
    parent_product_name: string
    parent_display_name: string
    parent_product_description: string
    slug: string
    price: number
    allow_oversell: number
    name: string
    sale_price: number
    thumbnail: ImageWithOverlay
    average_rating: number
    site_details: ProductChildren[]
    variations: number
    label_details?: Label
    search_keywords: string
    sku: string
    salePrice: number
    stock: number
    parent: ProductParent
    supplier: Supplier
    reviews_count: number
    supplier_label: string
    galleryImages: ImageWithOverlay[]
    galleryImagesBE: Image[]
    supplierLogo: Image
    wishlisted: boolean
    label?: Label
    model?: string
    categories: Category[]
    description: string
    seo: Record<any, any>
    // why
    session_token?: any
    productResource?: any
    redirect?: any
    type?: any
    warranty?: string
    attributes?: AttributesArray | []
    modelName?: {
      name: string
      slug:string
    }
    specsData:{
      name:string,
      value:string
    }[]
   dimensionData:{
      name:string,
      value:string
   }[]
   performanceSpecsData:{
      name:string,
      value:string
   }[]
   featuresData:{
      name:string,
      value:string
   }[]
}
export const OVERLAY_POSITIONS = [
    'top-left',
    'top-center',
    'top-right',
    'middle-left',
    'middle-center',
    'middle-right',
    'bottom-left',
    'bottom-center',
    'bottom-right',
] as const

export type OverlayPosition = (typeof OVERLAY_POSITIONS)[number]

export type Image = {
    name: string
    path: string
    url: string
}
export type AssetOverlay = {
    overlay_id: number
    position: OverlayPosition
    image_id: number
    image: Image
    title: string
    site_id: number
    padding?: number
    horizontalMargin?:number
    verticalMargin?:number
}
export type ImageWithOverlay = Image & {
    overlays: AssetOverlay[]
}

export type RangeDocument = {
    file: DocumentFile,
    id: number,
    title: string
}

type DocumentFile = {
    name: string,
    path: string,
    url: string
}

type ProductDocument = {
    id: number | string
    content: ProductContent
}

export type ProductChildren = {
    product_id: number
    site_id: number
    name: string
    slug: string
}

// To match with the API result: ...productResource.parent.child_products
export type ProductChild = {
    id: number
    name: string
    slug: string
}
// To match with the API result: ...productResource.parent.child_products
export type ProductParent = {
    id: number
    name: string
    slug: string
    parent_attributes: ParentAttribute[]
    child_products: ProductContent[]
    dimension_fields?: number[]
    specification_fields?: number[]
}

export type ParentAttribute = {
    attribute: Attribute
    value: string
    config: { value: string }
}[]
export type RangeAttribute = { id: number; name: string; values?: string[]; config: any }

export type Attribute = { id: number; name: string; field_type: string }
export type Supplier = {
    id: number
    name: string
    slug: string
    logo: string
    supplier_eta: number
}

export type ProductDetailsResponse =
    | {
    session_token: string
    shippingAmount: number
    productResource: ProductContent
    averageRatings: ProductAverageRating
    isWishlisted: boolean
    redirect: false
}
    | {
    redirect: true
    slug: string
    type: 'product' | 'category' | 'home'
}

export type ProductCompareResponse = {
    averageRatings: ProductAverageRating
    productResource: ProductContent
    shippingAmount: number
    redirect?: boolean
}

export type ProductAverageRating = {
    allFields: ProductRating[]
    overallAverage: string
}

type ProductRating = {
    rating: number
    field_id: number
    review_id: number
}

export function toTProductCard(products: Product[]): TProductCard[] {
    return products?.map((product) => {
        const {
            document: {content},
        } = product
        const name = content.parent_product_name
            ? `${content.parent_product_name} - ${content.name}`
            : content.name
        return {
            id: product.document.id as number,
            name,
            thumbnail: content.thumbnail,
            variations: content.variations,
            average_rating: content.average_rating,
            slug: content.slug,
            sale_price: content.sale_price,
            wishlisted: content.wishlisted,
            price: content.price,
            label: content.label_details || null,
            supplierLogo: content.supplierLogo || null,
        }
    })
}

export function toTRangeCard(items: any, dtype: string): TRangeCard[] {

    return items?.map((item: any) => {

        //PRODUCT CARDS
        //if is a products array (dtype = products)
        //the products are a different shape to ranges and need to be mapped differently to display nicely on the cards
        if (dtype === 'products') {
            return {
                id: item.model.id,
                name: (item.document.content.parent_display_name ?? item.document.content.parent_product_name) + (item.document.content.name ? ` - ${item.document.content.name}` : ''),
                thumbnail: item.document.content.thumbnail,
                slug: item.document.content.slug,
                content: '',
                description: item.document.content.description,
                price: item.document.content.sale_price,
                supplier: item.document.content.supplier_name,
                label: item.document.content.label_details,
                model: item.model.model,
            }
        }

            //RANGE CARDS
        //if is a ranges array
        else {
            console.log(item)
            return {
                name: item.name,
                thumbnail: item.thumbnail,
                slug: item.slug,
                content: item.content,
                description: item.description,
                availableSizes: item.availableSizes,
            }
        }
    })
}

export type SortByType = {
    id: number
    title: string
    value: string
}
