'use client'
import {truncate} from 'lodash'
import Image from 'next/image'
import Link from 'next/link'
import {ReactNode} from 'react'
import {ImageWithOverlay, Label} from '../../../types'
import ImageOverlays from '../../ImageOverlays'
import AddToCompareButton from '../../compares/AddToCompareButton'

export type TRangeCard = {
    id?: number
    slug: string
    thumbnail: ImageWithOverlay
    name: string
    description: string
    content: string
    model: string
    supplier?: string
    label?: Label
    availableSizes: string
}

export default function CaliboProductCard({item, isProduct, isComparePage = false}: {
    item: TRangeCard,
    isProduct: boolean,
    isComparePage: boolean
}) {

    const {id = 0} = item;
    return (
        <LinkOrNot isProduct={isProduct} item={item}>
            <div className="calibo_product-grid-card">
                <div className="relative group">
                    {/* No label available on ranges at this point -- only on the product cards */}
                    {item.label && item.label.short_name && (
                        <div
                            style={{
                                background: item.label.color || '',
                                color: item.label.text_color ? item.label.text_color : '',
                            }}
                            className="product-label"
                        >
                            {item.label.short_name}
                        </div>
                    )}
                    <div className="image-with-overlays">
                        <Image
                            height={500}
                            width={500}
                            alt={item.name}
                            className={`${isProduct ? 'cursor-auto ' : 'cursor-pointer '}product-image`}
                            src={item.thumbnail.url}
                            loading="eager"
                        />
                    </div>
                    <ImageOverlays type="thumbnail" overlays={item.thumbnail.overlays}/>
                </div>
                <div className="product-info">
                    <div className="top-labels">
                        {item.supplier && <span>{item.supplier}</span>}
                    </div>
                    {!isProduct ?
                        <Link href={`/range/${item.slug}`}>
                            <h4 className="title">{truncate(`${item.name}`, {length: 100})}</h4>
                        </Link> :
                        <h4 className="title">{`${item.name}`}</h4>
                    }
                    {(item.model || item.availableSizes) && (
                        <span className="price-from">
              {
                  !isProduct
                      ? (item.availableSizes && item.availableSizes !== "") && `Available Sizes : ${item.availableSizes}`
                      : (item.model && item.model !== '') && `Model : ${item.model}`
              }
            </span>
                    )}
                    {isProduct && !isComparePage ? <AddToCompareButton className='compare-button' product={{
                        id: id,
                        name: item.name,
                        slug: item.slug,
                        img: item.thumbnail.url
                    }}/> : !isComparePage ? <Link href={`/range/${item.slug}`} className="details-button">
                        View Details
                    </Link> : null}
                </div>
            </div>
        </LinkOrNot>
    )
}

function LinkOrNot({children, isProduct, item}: { children: ReactNode, isProduct: boolean, item: TRangeCard }) {
    return (
        <>
            {!isProduct ?
                <a href={`/range/${item.slug}`} className={"cursor-auto"}>{children}</a>
                :
                <>
                    {children}
                </>
            }
        </>
    )
}
